import {transformQueryParams} from "~/utils/map";
import {MayBeReactive} from "~/utils/types";

type Filter = {
  title?: string;
  order?: "desc" | "asc";
};

export const useArticles = (filter: MayBeReactive<Filter> = {}) => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    "articles",
    async () => {
      const queryParams = transformQueryParams(
        isRef(filter) ? filter.value : filter
      );

      const {
        data: {
          data: {
            articles: {data},
          },
        },
      } = await $api.get(`/articles${queryParams}`);

      return data;
    },
    {
      server: false,
      lazy: false,
      ...(isRef(filter) && {watch: [filter]}),
      default: () => [],
    }
  );

  const articles = computed(() => {
    return data?.value || [];
  });

  return {
    articles,
    loading: pending,
  };
};

export const useArticle = (id: string) => {
  const {$api} = useNuxtApp();

  const {data, pending} = useAsyncData(
    `article-${id}`,
    async () => {
      const {
        data: {
          data: {article},
        },
      } = await $api.get(`/articles/${id}`);

      return article;
    },
    {default: () => ({})}
  );

  const article = computed(() => {
    return data?.value || [];
  });

  return {
    article,
    loading: pending,
  };
};
